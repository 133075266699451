export const faqList = [
  {
    id: 1,
    heading: `1. When and where is the Green Heartfulness Run event?`,
    body: `The event is scheduled for Sunday, Nov 19th, 2023. The start of the race is scheduled to be at Kanha Shantivanam. A detailed route map will be available closer to the event date.`,
  },
  {
    id: 2,
    heading: `2. What are the race categories at Green Heartfulness Run?`,
    body: `<p class="lh-small tour_txt panel" style="max-height: 136px;">Half Marathon (21.1 km) <br> 10 km <br> 5 km <br> Fun and Family Run (2 km) <br> Global Run: ₹ 99, ₹ 399, ₹ 400, ₹ 599 <br> All races except the 2km race are timed.
    </p>`,
  },
  {
    id: 3,
    heading: `3. What is the race route?`,
    body: `The race route will be announced at a later date.`,
  },
  {
    id: 4,
    heading: `4. How do I apply for the event?`,
    body: `<p class="lh-small tour_txt panel" style="max-height: 54px;">You can apply online by <a href="https://www.greenkanharun.com" target="_blank" rel="noopener">clicking this link.</a> Payment can be done through Debit Card/Credit Card, Select wallets or Net Banking. For any queries, you may also email us at <a href="mailto:greenheartfulnessrun@heartfulness.org">greenheartfulnessrun@heartfulness.org </a></p>`,
  },
  {
    id: 5,
    heading: `5. How much is the application fee?`,
    body: `Half Marathon (21.1KMS): ₹ 800/- <br>
    10KMS: ₹ 700/- <br>
    5KMS: ₹ 600/- <br>
    Fun &amp; Family Run 2K: ₹ 500/-<br>
    Global Run: ₹ 99, ₹ 399, ₹ 400, ₹ 599`,
  },
  {
    id: 6,
    heading: `6. What does the application fee cover?`,
    body: `The fee covers your entry to the event, your bib with timing chip except 2km, access to race day facilities for registered participants and the following – Running T-Shirt, Medal, Timing Certificate, Breakfast, Goodie Bag.`,
  },
  {
    id: 7,
    heading: `7. Can I Apply Offline?`,
    body: `No`,
  },
  // {
  //   id: 8,
  //   heading: `8. Is there a minimum age for participants?`,
  //   body: `For the Half Marathon, the minimum age to participate is 18 years as on the race day. For the 10K Run, the minimum age to participate is 15 years as on the race day. For the 5K the minimum age to participate is 8 years as on the race day.    `,
  // },
  {
    id: 9,
    heading: `8. What is the last date to apply for participating in the event?`,
    body: `The date of registration closing will be disclosed soon.`,
  },
  {
    id: 10,
    heading: `9. Can I cancel my registration?`,
    body: `No. Cancellations are not allowed.`,
  },
  {
    id: 11,
    heading: `10. Where do I collect my running bib? `,
    body: `More details on the expo including the bib collection will be available closer to the event date.`,
  },{
    id: 12,
    heading: `11. What do I need to bring with me to collect my running bib?`,
    body: `You must bring your application confirmation e-mail/SMS in a soft copy along with a valid Government-issued photo ID with you to collect your running number.`,
  },{
    id: 13,
    heading: `12. Can someone else collect the running bib on my behalf? `,
    body: `Yes. You can nominate someone to collect your bib for you. The person must carry a letter written and signed by you authorizing the person by name to receive your running bib. The person must have suitable photographic identification with him/her.`,
  },{
    id: 14,
    heading: `13. I am a visually impaired runner or runner with special needs. Where can I find out more about running with a guide?`,
    body: `Please email us at <a href="mailto:greenheartfulnessrun@heartfulness.org">greenheartfulnessrun@heartfulness.org</a>, and we will be more than happy to assist you with this.`,
  },{
    id: 15,
    heading: `14. I have made a mistake in my registration fields. How do I correct it?`,
    body: `Please send an email to <a href="mailto:greenheartfulnessrun@heartfulness.org">greenheartfulnessrun@heartfulness.org</a> address with your correct details, and it will be updated.`,
  },{
    id: 16,
    heading: `15. I have moved. How do I let you know my change of address?`,
    body: `Please email us at <a href="mailto:greenheartfulnessrun@heartfulness.org">greenheartfulnessrun@heartfulness.org</a> and we will be happy to assist you with this.`,
  },{
    id: 17,
    heading: `16. I am an overseas applicant, can I apply for the race? What documents do I need?`,
    body: `You can apply online only. You will need to provide us with a government-issued ID proof and a photograph on the application site. Please note that runners ONLY of Indian origin are eligible for any prize money.    `,
  },{
    id: 18,
    heading: `17. How do I train for the race?`,
    body: `You can use our uniquely designed training programmes for the race. You will get more information via email after registration.`,
  },{
    id: 19,
    heading: `18. How do I know what I have to do on Race Day?`,
    body: `You will get an email on your registered email id with Race Day instructions closer to the race.`,
  },{
    id: 20,
    heading: `19. Will there be water/energy drinks available during the race to keep me hydrated during the race?`,
    body: `We will be providing water, electrolytes and toilet facilities during the race. More details on the event will be available close to the event date.`,
  },{
    id: 21,
    heading: `20. As a spectator, any advice you can give me for watching the race?`,
    body: `You can watch the race from any location on the race route. Please do follow the advice of the route marshals and volunteers on where to stand and watch.`,
  },{
    id: 22,
    heading: `21. I will be a spectator for the race. Can I track a runner during the race?`,
    body: `You can track runners on the event website. Please do check the same closer to the event date.`,
  },{
    id: 23,
    heading: `22. Will there be TV coverage or streaming available for Green Heartfulness Run event`,
    body: `We are working towards making it available. Updates will be available if this becomes viable.`,
  },{
    id: 24,
    heading: `23. Where can I find the results?`,
    body: `Provisional results will be shared on the event website within 12 hours of the race finish. Final results will be shared in 21 working days after the race post verification.`,
  },{
    id: 25,
    heading: `24. Can I choose to raise money for another charity of my choice?    `,
    body: `The cause will be communicated to all participants soon.`,
  },{
    id: 26,
    heading: `25. Who are the other sponsors of the event? `,
    body: `The event website has all the details of the sponsors.`,
  },{
    id: 27,
    heading: `26. How can I become a sponsor of the race?`,
    body: `Please email us at <a href="mailto:greenheartfulnessrun@heartfulness.org">greenheartfulnessrun@heartfulness.org</a> for details.`,
  },
];
