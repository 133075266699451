import React from "react";

import FAQpage from "../components/sharedComponents/faq";

import Seo from "../components/seo/index";

import Layout from "../layout/index";

// markup
const faqpage = () => {
  return (
    <div>
      <Seo title="Green Heartfulness Run 2024 - FAQ" />
      <Layout isSticky>
        <FAQpage />
      </Layout>
    </div>
  );
};

export default faqpage;
