import React from "react";

import "../../assets/styles/pages/faq/faq.scss";

import { faqList } from "../../assets/data/faq";

import { Accordion } from "react-bootstrap";

import Faqbreadcrumbpages from  "../raceinfo/faqbreadcrumb";

const FAQpage = () => {
  return (
    <>
      <section className=" faq-green inner-pages">
      <Faqbreadcrumbpages />
        <div className="container" style={{marginBottom: '100px'}}>
          <div className="row py-4">
            <div className="col-md-12">
              <h4 className="title-heading">FAQs</h4>

              <Accordion className="exp-faq-accordion-wrapper">
                {faqList.map((item) => {
                  return (
                    <Accordion.Item
                      className="exp-accordian mt-4"
                      eventKey={item.id}
                      key={item.id}
                    >
                      <Accordion.Header className="exp-body ">
                        <b> {item.heading}</b>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div
                          className="lh-small"
                          dangerouslySetInnerHTML={{ __html: item.body }}
                        ></div>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default FAQpage;
